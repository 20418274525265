//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import CreatorPaginated from "@/components/CreatorPaginated";
import { contest_service } from "@/services";
export default {
  name: "top-creator-page",
  components: {
    BaseLayout,
    CreatorPaginated,
  },
  metaInfo: {
    title: "Top Creators",
  },
  data() {
    return {
      contests: [],
      tab: "",
    };
  },
  mounted() {
    this.load_live_monthly_contests();
  },

  methods: {
    load_live_monthly_contests() {
      contest_service
        .get({ ordering: "-start", visible_in_top_creators: "true", type__name: "Monthly" })
        .then((data) => {
          this.contests.push(...data.results);
          if (this.contests.length > 0)
            this.tab = this.contests[0].name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    on_profile_select(creator) {
      this.$router.push({ name: "profile", params: { id: creator.id } });
    },
  },
};
